import { TableContainer, Grid, Table, TableRow, TableHead, TableBody, TableCell, Typography, Paper, Button, TextField } from "@mui/material"
import Api from "../../api";
import { useLoaderData } from "react-router-dom";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import Label, { getLabel } from "../../utils";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import UserContext from "../../context/userContext";

interface ITag {
    id: number;
    created: string;
    tag: string;
}

interface IPostSettings {
    tags: [ITag]
}

export async function postSettingsLoader() {
    return Api.get('/intranet/settings/tags');
}

export default function Posts() {
    const userContext = useContext(UserContext);
    const { setSnackbarText, userData } = userContext;
    const data: IPostSettings = useLoaderData() as IPostSettings;
    const [tags, setTags] = useState<ITag[]>(data.tags);
    const [originalTags, setOriginalTags] = useState<ITag[]>(data.tags);
    const [showSaveButton, setShowSaveButton] = useState(false);


    async function saveTags() {
        const data = {
            tags: tags
        };

        try {
            const res = await Api.post('/intranet/settings/tags', data);
            setShowSaveButton(false);
            setSnackbarText(getLabel("snackbar.postSaved", userData.user.lang));
        } catch (error) {
            setSnackbarText(getLabel("snackbar.somethingWentWrong", userData.user.lang));

            console.error(error);
        }
    }

    useEffect(() => {
        const hasChanges = _.isEqual(originalTags, tags);
        setShowSaveButton(!hasChanges);
    }, [tags]);

    return (
        <>
            <Typography variant="h5"><Label k="settings.posts.header" /></Typography>
            <Typography variant="caption" color="text.secondary"><Label k="settings.posts.expl" /></Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><Label k="settings.posts.tableCol.created" /></TableCell>
                        <TableCell><Label k="settings.posts.tableCol.tag" /></TableCell>
                        <TableCell align="right"><Label k="settings.posts.tableCol.action" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tags.map((row: ITag, i: number) => (
                        <TableRow key={"row_" + i}>
                            <TableCell>{dayjs(row.created).format("DD.MM.YY")}</TableCell>
                            <TableCell>
                                <TextField key={i + "_test"} id="outlined-basic" value={row.tag} size="small" onChange={(e) => {
                                    setTags(tags.map((tag, index) => {
                                        if (index === i) {
                                            return { ...tag, tag: e.target.value }
                                        }
                                        return tag;
                                    }))
                                }} /></TableCell>
                            <TableCell align="right"><Button startIcon={<DeleteIcon />} color="error" onClick={() => {
                                setTags(tags.filter((tag, index) => index !== i))
                            }}><Label k="settings.posts.tableCol.actionCta" /></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Grid container justifyContent={"space-between"}>
                <Grid item>
                    <Button startIcon={<AddIcon />} className='themeButton' variant="text" sx={{ marginTop: "1rem" }} onClick={() => {
                        setTags([...tags, { id: 0, created: dayjs().format(), tag: "Ny tag" }])
                    }}><Label k="settings.posts.addNewTagCta" /></Button>
                </Grid>
                <Grid item>
                    {showSaveButton && <Button variant="outlined" className="themeButton" sx={{ marginTop: "1rem" }} onClick={saveTags}><Label k="settings.posts.saveCta" /></Button>}
                </Grid>
            </Grid>

        </>
    )
}