import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { useNavigate, useLocation } from "react-router-dom";

import UserContext, { IUserData, UserProvider } from './context/userContext';
import PostEdit, { postEditLoader } from './modules/intranet/shared/news/PostEdit';
import PostList, { postListLoader } from './modules/intranet/shared/news/PostList';
import SettingsPage from './modules/settings/MainSettingsPage';
import Home, { homeLoader } from './modules/intranet/Home';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import Api, { BASE_ROUTE } from './api';
import Header from './components/Header';
import _ from 'lodash';
import General, { generalSettingsLoader } from './modules/settings/General';
import Shortcuts, { linkSettingsLoader } from './modules/settings/Shortcuts';
import Posts, { postSettingsLoader } from './modules/settings/Posts';
import PostView2, { postLoader } from './modules/intranet/shared/news/PostView2';
import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ProtectedRoutes = () => {
  const { setUserData, snackbarText, setSnackbarText } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getUser() {
      try {

        const res = await Api.get('/intranet/me');
        setUserData(res as IUserData);

        const root = document.documentElement;
        root.style.setProperty('--primary-color', _.get(res, 'company.primary_color', '#638aab'));
        root.style.setProperty('--cta-color', _.get(res, 'company.cta_color', '#f5f5f5'));

        setIsLoading(false);
      } catch (e) {
        window.open('/auth/sign_in', '_self')
      }
    }
    getUser();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSnackbarText("");
    }, 2000); 
  }, [snackbarText]);

  return isLoading ?
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
      onClick={() => { }}
    >
      <CircularProgress color="inherit" />
    </Backdrop> :
    <>
      <Header />
      <Outlet />
      <Snackbar 
        open={snackbarText !== ""} 
        anchorOrigin={{ vertical:'bottom', horizontal: 'center' }}
        message={snackbarText}/>
    </>;
};

function Fallback() {
  return <>Page is not found</>
}

function ErrorFallback() {

  return (<Grid container justifyContent="center" alignItems="center" sx={{ height: "90vh", textAlign:"center" }}>
    <Grid item>
      <ErrorOutlineIcon sx={{ fontSize: "4rem" }} />
      <Typography variant="h6">Something went wrong</Typography>
    </Grid>
  </Grid>)
}


const router = createBrowserRouter([
  {
    element: <ProtectedRoutes />,
    path: BASE_ROUTE,
    children: [
      {
        path: "",
        element: <Home />,
        loader: homeLoader,
        errorElement:  <ErrorFallback />,
      },
      {
        path: "posts",
        element: <PostList />,
        loader: postListLoader,
        errorElement: <ErrorFallback />,
      },
      {
        path: "posts/:id",
        element: <PostView2 />,
        loader: postLoader,
        errorElement: <ErrorFallback />,
      },
      {
        path: "posts/:id/edit",
        element: <PostEdit />,
        loader: postEditLoader,
        errorElement: <ErrorFallback />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
        children: [
          {
            path: "general",
            element: <General />,
            loader: generalSettingsLoader,
            errorElement: <ErrorFallback />,
          },
          {
            path: "shortcuts",
            element: <Shortcuts />,
            loader: linkSettingsLoader,
            errorElement: <ErrorFallback />,
          },
          {
            path: "posts",
            element: <Posts />,
            loader: postSettingsLoader,
            errorElement: <ErrorFallback />,
          },
        ]
      },
    ]
  },
  {
    path: '/*',
    element: <Fallback />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <UserProvider>
    <RouterProvider router={router} />
  </UserProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
