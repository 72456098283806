import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { BASE_ROUTE } from '../../../../api';
import { IPost } from './interface';
import style from './Post.module.css';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Label, { formatDate } from '../../../../utils';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useContext } from 'react';
import UserContext from '../../../../context/userContext';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const maxLengthPreview = 150;

export function get_article_preview(content, length = maxLengthPreview) {
    let text = content?.blocks.map(block => {
        if (block.type === 'paragraph') {
            return block.data.text;
        }
        return ' ';
    }).join(' ');

    return text;
}

function PostPreview({ post, isLast }: { post: IPost, isLast?: boolean }) {
    const navigate = useNavigate();

    const userContext = useContext(UserContext);
    const { userData, roles } = userContext;

    let image = post.image;
    if (!image) {
        let firstImage = _.find(post.content.blocks, { type: 'image' });
        if (firstImage) {
            image = firstImage.data.file.url;
        }
    }
    if (!image) {
        image = _.get(userData, "company.logo_path");
    }

    return (
        <Link to={`${BASE_ROUTE}/posts/${post.id}`} style={{ textDecoration: "none", }}>
            <Card className={style.postPreviewCardWrapper} sx={{ boxShadow: 0, borderBottom: isLast ? '0' : '1px solid #e6e6e6', maxHeight: "10rem" }}>
                <Grid container>
                    <Grid item md={3}>
                        <CardMedia
                            component="img"
                            className={style.postPreviewImage}
                            sx={{ objectFit: post.stretch_image ? "cover" : "contain" }}
                            image={image}

                            alt="Post image"
                        />
                    </Grid>
                    <Grid item md={9}>
                        <CardContent className={style.postPreviewCard}>
                            <div className={style.postPreviewText}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="body2" color="text.secondary"  >{post.authors.join(", ")}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" color="text.secondary"  >{formatDate(post.created)}</Typography>
                                    </Grid>
                                </Grid>
                                <Typography component="div" variant="h6" sx={{ paddingTop: "0", marginTop: "0" }}>{post.title}</Typography>

                                <Grid item>
                                    <Typography variant="body2" color="text.secondary" sx={{ textOverflow: "ellipsis" }} >{get_article_preview(post.content)}</Typography>
                                </Grid>
                            </div>
                            <div className={style.postPreviewTags}>
                                <div className={style.postPreviewTransitionLayer}></div>
                                <div className={style.postPreviewTagsContent}>
                                    <Grid container justifyContent={"space-between"} alignItems={"flex-end"} >
                                        <Grid item>
                                            <Button variant="text" endIcon={<KeyboardDoubleArrowRightIcon />} className='themeButton' onClick={() => navigate(`${BASE_ROUTE}/posts/${post.id}`)}><Label k="home.stickyPostCta" /></Button>
                                        </Grid>
                                        <Grid item>
                                            {post.tags.map((tag, index) => <div key={index} className={style.tag}>{tag}</div>)}
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </CardContent>
                    </Grid>
                </Grid>
            </Card>
        </Link>
    );
}

export default PostPreview;


export function IntranetPostPreview(props: any) {
    const navigate = useNavigate();
    const posts = props.data as IPost[];

    return <div style={{ padding: "1rem", }}>
        <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item>
                <div className="intranet_tile_header"><Label k="home.newsArticleHeader" /></div>
            </Grid>
            <Grid item>
                <Button startIcon={<ViewListIcon />} className='themeButton' onClick={() => navigate(`${BASE_ROUTE}/posts`)}><Label k="home.allArticlesCta" /></Button>
            </Grid>
        </Grid>
        {posts?.map((post, index) => <PostPreview key={index} post={post} isLast={index + 1 == posts.length} />)}
    </div>;
}