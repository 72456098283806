import React, { useContext, useEffect, useState } from 'react';
import { Reorder } from 'framer-motion';
import { TableContainer, Table, TableRow, TableHead, TableBody, TableCell, Typography, TextField, Button, Grid, styled, Modal, Box, FormGroup } from "@mui/material"

import ImportExportIcon from '@mui/icons-material/ImportExport';
import Api from '../../api';
import { useLoaderData } from 'react-router-dom';
import { link } from 'fs';
import { v4 as uuidv4 } from 'uuid';
import UploadImage from '../../components/UploadImage';
import style from "./MainsettingsPage.module.css";
import _ from 'lodash';
import Label, { getLabel } from '../../utils';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import UserContext from '../../context/userContext';

const url = '/intranet/settings/links';

export async function linkSettingsLoader() {
    return Api.get(url);
}

interface ILinkGroup {
    id: number,
    name: string;
    link: [ILink];
}

interface ILink {
    id: number;
    title: string;
    url: string;
    order: number;
    group: number;
    image: string | null;
}





function LinkGroup(props) {
    const { setShowEditModal } = props;
    const { name, link } = props.linkgroup;
    const [links, setLink] = useState(link);

    return (
        <>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><Label k="settings.shortcuts.tableCol.name" /></TableCell>
                        <TableCell><Label k="settings.shortcuts.tableCol.link" /></TableCell>
                        <TableCell><Label k="settings.shortcuts.tableCol.link" /></TableCell>

                        <TableCell align="right"><Label k="settings.posts.tableCol.action" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {links.map((item, i) => (
                        <TableRow key={"row_" + i}>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.url?.length < 25 ? item.url : item.url.slice(0, 25) + "..."}</TableCell>
                            <TableCell><img src={item.image} className={style.shortcutimage} /></TableCell>
                            <TableCell align="right">
                                <Button startIcon={<EditIcon />} onClick={() => setShowEditModal(item, i)}><Label k="settings.shortcuts.editCta" /></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}


export default function Shortcuts() {
    const userContext = useContext(UserContext);
    const { setSnackbarText, userData } = userContext;
   
    const data: any = useLoaderData();
    const [linkGroups, setLinkGroups] = useState(data.links);

    // link modal fields
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [editLink, setEditLink] = useState<ILink>(null);
    const [imageFile, setFile] = useState(null);

    // linkgroup modal fields
    const [showLinkGroupModal, setShowLinkGroupModal] = useState(false);
    const [editLinkGroup, setEditLinkGroup] = useState<ILinkGroup>(null);
    const [editLinkList, setEditLinkList] = useState<any>([]);



    async function handleCloseModal(shouldUpdateValues, shouldDelete = false) {
        if (shouldUpdateValues) {
            const formData = new FormData();
            if (imageFile instanceof File) {
                formData.append("file", imageFile, `${uuidv4()}.${imageFile.name.split('.').pop()}`);
            }
            formData.append("data", JSON.stringify(editLink));
            try {
                const res = await Api.upload(url, formData);
                setSnackbarText(getLabel("snackbar.postSaved", userData.user.lang));
                setLinkGroups(prev => [...res.links]);
            } catch (e) {
                setSnackbarText(getLabel("snackbar.somethingWentWrong", userData.user.lang));
                console.error(e);
            }
        } else if (shouldDelete) {
            try {
                const res = await Api.delete(url, { id: editLink.id });

                setLinkGroups(prev => [...res.links]);
            } catch (e) {
                setSnackbarText(getLabel("snackbar.somethingWentWrong", userData.user.lang));
                console.error(e);
            }
        }
        setFile(null);
        setEditLink(null);
        setShowLinkModal(false);

    }
    const handleCloseLinkGroupModal = async (shouldUpdateValues, shouldDelete = false) => {
        if (shouldUpdateValues) {
            editLinkGroup.link = editLinkList.map((item, index) => {
                return { ...item, order: index + 1 }
            });
            const data = {
                group: editLinkGroup
            }
            try {
                const res = await Api.put('/intranet/settings/links/group', data);
                setLinkGroups(prev => [...res.links]);
                setSnackbarText(getLabel("snackbar.postSaved", userData.user.lang));

            } catch (e) {
                setSnackbarText(getLabel("snackbar.somethingWentWrong", userData.user.lang));
                console.log(e);
            }
        } else if (shouldDelete) {
            try {
                const res = await Api.delete('/intranet/settings/links/group', { id: editLinkGroup.id });
                setLinkGroups(prev => [...res.links]);
                setSnackbarText(getLabel("snackbar.postSaved", userData.user.lang));

            } catch (e) {
                console.log(e);
            }
        }
        setEditLinkGroup(null);
        setShowLinkGroupModal(false);
    }


    return (
        <>
            <Modal
                open={showLinkModal}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={style.shortcutModal} >
                    <Grid container justifyContent={"space-between"} sx={{marginBottom:"2rem"}}>
                        <Grid item>
                        <Typography variant='h6' sx={{ marginBottom: "1rem" }}><Label k="settings.shortcuts.modal2.header" /></Typography>

                        </Grid>
                        <Grid item>
                            <Button startIcon={<DeleteIcon />} color="warning" onClick={() => handleCloseModal(false, true)}><Label k="settings.shortcuts.modal.deleteCta" /></Button>

                        </Grid>
                    </Grid>
                    <Grid container spacing={3} direction="column" >
                        <Grid item>
                            <TextField fullWidth label={<Label k="settings.shortcuts.modal2.nameLabel" />} variant="outlined" value={editLink?.title} onChange={(e) => setEditLink(prev => ({ ...prev, title: e.target.value }))} />
                        </Grid>
                        <Grid item>
                            <TextField fullWidth label={<Label k="settings.shortcuts.modal2.linkLabel" />} variant="outlined" value={editLink?.url} onChange={(e) => setEditLink(prev => ({ ...prev, url: e.target.value }))} />
                        </Grid>
                        <Grid item>
                            <Typography variant='h6' >Icon</Typography>
                            <UploadImage file={imageFile} setFile={setFile} />
                        </Grid>

                        <Grid item>
                            <Grid container justifyContent="space-between" sx={{ marginTop: "3rem" }}>
                                <Grid item>
                                    <Button startIcon={<CloseIcon />} className='themeButton' onClick={() => handleCloseModal(false)}><Label k="settings.shortcuts.modal.cancelCta" /></Button>
                                </Grid>

                                <Grid item>
                                    <Button startIcon={<SaveIcon />} className='themeButton' variant="outlined" onClick={() => handleCloseModal(true)}><Label k="settings.shortcuts.modal.saveCta" /></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={showLinkGroupModal}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={style.shortcutModal} >
                    <Grid container spacing={3} direction="column" >
                        <Grid item>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                <Typography variant='h5' sx={{ marginBottom: "2rem" }}><Label k="settings.shortcuts.modal.header" /></Typography>
                                </Grid>

                                <Grid item>
                                    <Button startIcon={<DeleteIcon />} color='warning' onClick={() => handleCloseLinkGroupModal(false, true)}><Label k="settings.shortcuts.modal.deleteCta" /></Button>
                                </Grid>

                            </Grid>
                            <TextField fullWidth label={<Label k="settings.shortcuts.modal.nameLabel" />} variant="outlined" value={editLinkGroup?.name} onChange={(e) => setEditLinkGroup(prev => ({ ...prev, name: e.target.value }))} />
                        </Grid>
                        <Grid item>
                            <Typography variant='h6' sx={{ marginBottom: "1rem" }}><Label k="settings.shortcuts.modal.orderTitle" /></Typography>
                            <Reorder.Group axis="y" values={editLinkList} onReorder={setEditLinkList} style={{ listStyle: "none", padding: 0 }} >
                                {editLinkList.map((item, i) => (
                                    <Reorder.Item key={i + item.title} value={item} >
                                        <Grid container justifyContent="flex-start" spacing={3} sx={{ cursor: "grab", marginBottom: "1rem" }}>
                                            <Grid item>
                                                <DragIndicatorIcon />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant='body1' sx={{ marginTop: "0.1rem" }}>{i + 1}. {item.title}</Typography>

                                            </Grid>
                                        </Grid>

                                    </Reorder.Item>))}
                            </Reorder.Group>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Button startIcon={<CloseIcon />} className='themeButton' onClick={() => handleCloseLinkGroupModal(false)}><Label k="settings.shortcuts.modal.cancelCta" /></Button>
                                </Grid>

                                <Grid item>
                                    <Button startIcon={<SaveIcon />} className='themeButton' onClick={() => handleCloseLinkGroupModal(true)}><Label k="settings.shortcuts.modal.saveCta" /></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>


            <Typography variant="h5" ><Label k="settings.shortcuts.header" /></Typography>
            <Typography variant="caption" color="text.secondary" ><Label k="settings.shortcuts.expl" /></Typography>

            {linkGroups.map((linkGroup: ILinkGroup, index: number) =>
                <div key={index} style={{ marginBottom: "3rem" }}>
                    <Grid container justifyContent={"flex-start"} alignItems={"center"} spacing={2} sx={{ marginTop: "0rem" }}>
                        <Grid item>
                            <Typography variant="h6">{linkGroup.name}</Typography>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<EditIcon />} className='themeButton' onClick={() => {
                                setEditLinkGroup(linkGroup);
                                setEditLinkList(linkGroup.link);
                                setShowLinkGroupModal(true);
                            }}><Label k="settings.shortcuts.editCta" /></Button>
                        </Grid>
                    </Grid>

                    <Table sx={{  }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><Label k="settings.shortcuts.tableCol.name" /></TableCell>
                                <TableCell><Label k="settings.shortcuts.tableCol.link" /></TableCell>
                                <TableCell><Label k="settings.shortcuts.tableCol.link" /></TableCell>
                                <TableCell align="right"><Label k="settings.posts.tableCol.action" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {linkGroup.link.map((item, i) => (
                                <TableRow key={"row_" + i}>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.url?.length < 20 ? item.url : item.url.slice(0, 20) + "..."}</TableCell>
                                    <TableCell><img src={item.image!} className={style.shortcutimage} /></TableCell>
                                    <TableCell align="right">
                                        <Button startIcon={<EditIcon />} className='themeButton' onClick={() => {
                                            setEditLink(item);
                                            setFile(item.image);
                                            setShowLinkModal(true);
                                        }}><Label k="settings.shortcuts.editCta" /></Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item>
                            <Button startIcon={<AddIcon />} className='themeButton' onClick={() => {
                                const emptyLink = { id: 0, title: "", url: "", order: linkGroup.link.length + 1, group: linkGroup.id, image: null };
                                setEditLink(emptyLink);
                                setShowLinkModal(true);
                            }}><Label k="settings.shortcuts.addLinkCta" /></Button>
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </div>
            )}
            <hr />
            <Grid container sx={{ marginTop: "1rem" }} justifyContent={"flex-start"}>
                <Grid item>
                    <Button variant="outlined" className='themeButton' startIcon={<AddIcon />} onClick={async () => {
                        try {
                            const res = await Api.post('/intranet/settings/links/group', data);
                            setLinkGroups(prev => [...res.links]);
                            setSnackbarText(getLabel("snackbar.postSaved", userData.user.lang));
    
                        }catch(e){
                            setSnackbarText(getLabel("snackbar.somethingWentWrong", userData.user.lang));
                            console.log(e);
                        }

                    }}><Label k="settings.shortcuts.addLinkGroupCta" /></Button>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
        </>

    );
};

