export const BASE_URL = "http://localhost:3000";
// export const BASE_URL = "http://127.0.0.1:8000";
// export const BASE_URL = "";

//export const BASE_URL = "";
export const BASE_ROUTE = "/ui";

function getCSRFToken() {
    const csrfCookie = document.cookie.match(/csrftoken=([\w-]+)/);
    return csrfCookie ? csrfCookie[1] : '';
}

const Api = {
    get: async (url) => {
        try {

            const response = await fetch(url, {
                method: 'GET',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Request failed with status code ' + response.status);
            }
            return await response.json();
        } catch (error) {
            throw error.message;
        }
    },

    post: async (url, data = {}) => {
        const csrfToken = getCSRFToken();

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error('Request failed with status code ' + response.status);
            }
            return await response.json();
        } catch (error) {
            throw error.message;
        }
    },

    upload: async (url, formdata, method='POST') => {
        const csrfToken = getCSRFToken();

        try {
            const response = await fetch(url, {
                method: method,
                body: formdata,
                headers: {
                    'X-CSRFToken': csrfToken,
                }
            });
            if (!response.ok) {
                throw new Error('Request failed with status code ' + response.status);
            }
            return await response.json();
        } catch (error) {
            throw error.message;
        }
    },

    put: async (url, data = {}) => {
        const csrfToken = getCSRFToken();

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error('Request failed with status code ' + response.status);
            }
            return await response.json();
        } catch (error) {
            throw error.message;
        }
    },

    delete: async (url, jsonData = null) => {
        const csrfToken = getCSRFToken();

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken,
                },
                body: jsonData ? JSON.stringify(jsonData) : null
            });
            if (!response.ok) {
                throw new Error('Request failed with status code ' + response.status);
            }
            return await response.json();
        } catch (error) {
            throw error.message;
        }
    },
};
export default Api;