import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api, { BASE_ROUTE } from '../../../../api';
import { Button, Container, Grid, Pagination, Typography, FormControl, InputLabel, Select, MenuItem, Alert, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoaderData } from 'react-router-dom';
import PostPreview from './PostPreview';
import { IPost } from './interface';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Label, { formatDate, getLabel } from '../../../../utils';
import UserContext from '../../../../context/userContext';



export async function postListLoader() {
    return {}
}


export default function PostList(props) {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const userContext = useContext(UserContext);
    const { userData, roles, setSnackbarText } = userContext;

    const [paginationInfo, setPaginationInfo] = useState({ total_pages: 0, total_posts: 0 });
    const [page, setPage] = useState(1);
    const [posts, setPosts] = useState([]);
    const [draftPosts, setDraftPosts] = useState([]);
    const [showDrafts, setShowDrafts] = useState(false);
    const [tags, setTags] = useState([]);
    const [selectedTagId, setSelectedTagId] = useState(0);


    async function getPosts(pageNum) {
        try {
            let optionalTag = "";
            if (selectedTagId != 0) {
                optionalTag = `&tagId=${selectedTagId}`
            }
            const res = await Api.get(`/intranet/posts?page=${pageNum}${optionalTag}`);
            const posts: IPost[] = res.posts as IPost[];

            setPaginationInfo(res.pagination);
            setPosts(posts);
            setDraftPosts(res.draft_posts);
            setTags(res.tags);
        } catch (error) {
            console.error(error);

            setSnackbarText(getLabel("snackbar.somethingWentWrong", userData.user.lang));
        }
    }

    useEffect(() => {
        getPosts(page);
    }, [page]);

    useEffect(() => {

        getPosts(1);
    }, [selectedTagId]);


    async function createNewPost() {
        try {
            const res = await Api.post('/intranet/posts');
            const post: IPost = res.post as IPost;
            navigate(`${BASE_ROUTE}/posts/${post.id}/edit`);
            setSnackbarText(getLabel('snackbar.createdNewPost', userData.user.lang));

        } catch (error) {
            console.error(error);
            setSnackbarText(getLabel('snackbar.somethingWentWrong', userData.user.lang));
        }
    }

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        setSearchParams({ page: value.toString() });
    };


    return (

        <Container>
            <Grid container maxWidth="xl" justifyContent={"center"} sx={{ margin: "2rem 0 2rem 0" }}>
                <Grid item md={8}>
                    <Grid container justifyContent={"space-between"}>
                        <Grid item md={3}>
                            <FormControl fullWidth variant="standard"  >
                                <InputLabel id="demo-simple-select-label"><Label k="postList.categoryLabel" /></InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedTagId}
                                    label="Kategori"
                                    onChange={(e) => {
                                        setSelectedTagId(e.target.value as number)
                                    }}                                >
                                    <MenuItem value={0}>Alle</MenuItem>
                                    {tags.map((tag) => <MenuItem key={tag.id} value={tag.id}>{tag.tag}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {roles.isPostEditor && <Button onClick={createNewPost} startIcon={<AddIcon />} variant='text' className='themeButton' ><Label k="postList.create" /></Button>}
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: "2rem" }}>
                        {roles.isPostEditor && draftPosts.length > 0 && <Alert
                            sx={{ marginBottom: "2rem" }}
                            severity="info"
                            action={
                                <Button color="inherit" size="small" onClick={() => setShowDrafts(!showDrafts)} > <Label k={showDrafts ? "postList.alertDraftTextCta2" : "postList.alertDraftTextCta"} /></Button>
                            }><Label k="postList.alertDraftText" /></Alert>
                        }
                        {showDrafts ? <>
                            <Table sx={{ width: "100%" }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Opprettet</TableCell>
                                        <TableCell>Forfatter</TableCell>
                                        <TableCell>Title</TableCell>
                                        <TableCell align="right"><Label k="settings.posts.tableCol.action" /></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {draftPosts.map((post, index) =>
                                        <TableRow key={index}>
                                            <TableCell>{formatDate(post.created)}</TableCell>
                                            <TableCell>{post.authors.join(', ')}</TableCell>
                                            <TableCell>{post.title}</TableCell>

                                            <TableCell align="right">
                                                <Button startIcon={<EditIcon />} className='themeButton' onClick={() => {
                                                    navigate(`${BASE_ROUTE}/posts/${post.id}/edit`);
                                                }}>Gå til sak</Button>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </> :
                            <>
                                <Typography variant="caption">Viser {posts.length} av {paginationInfo.total_posts}</Typography>
                                {posts.map((post, index) => <PostPreview key={index} post={post} />)}
                                <Pagination count={paginationInfo.total_pages} onChange={handleChange} />
                            </>}
                    </div>
                </Grid>

            </Grid>
        </Container>

    )
}