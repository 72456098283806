import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Api from '../../api';
import { Link, useLoaderData } from 'react-router-dom';

import { Calender } from './shared/calender-widget/calender';
import ShortCut from './shared/shortcuts/Shortcut';
import UpcomingEvents from './shared/upcoming-events/Upcoming';
import { componentList } from './component_list';
import style from './Home.module.css';
import UserContext from '../../context/userContext';
import _ from 'lodash';
import FlexDiagram from './shared/flexDiagram/FlexDiagram';
import Label from '../../utils';

export async function homeLoader() {
    return Api.get('/intranet/home');
}

interface IHomeComponent {
    key: string;
    data: any;
}

export interface IShortcutLink {
    title: string;
    url: string;
    image: string | null;
}

export interface IShortcut {
    name: string;
    link: [IShortcutLink];
}

interface IHomeProps {
    components: [IHomeComponent];
    shortcuts: [IShortcut]
}

function DynamicComponents(props) {
    const data: IHomeProps = useLoaderData() as IHomeProps;
    const components: [IHomeComponent] = data.components;
    const comps = components?.map((component, index) => {
        const Component = componentList.find(c => c.key === component.key);
        if (!Component) {
            console.error("Component not found", component);
            return;
        }
        const propsData = _.get(component, 'props.data', null);
        if (propsData) {
            return <div key={index} className={style.cardStyle}><Component.comp key={index} {..._.get(component, 'props', {})} /></div>
        }
    });
    return <>{comps}</>;
}

function Home(props) {
    const data: IHomeProps = useLoaderData() as IHomeProps;
    const userContext = useContext(UserContext);
    const { userData } = userContext;

    return (
        <>
            <div className={style.wrapper} >
                <Container maxWidth="xl">
                    <Typography variant='h5' style={{ color: "white", paddingTop: "2rem", marginLeft: "1rem" }}><Label k="home.firstnameGreeting" /> {_.get(userData, "user.firstname")}!</Typography>
                </Container>
            </div>
            <Container maxWidth="xl">
                <Grid container spacing={2} sx={{ margin: "4rem 0 3rem 0", position: "relative" }}>
                    <Grid item md={3} >
                        <div className={style.cardStyle} >
                            <Calender />
                        </div>
                    </Grid>
                    <Grid item md={7.5} >
                        <DynamicComponents />
                    </Grid>
                    <Grid item md={1.5} >
                        {data?.shortcuts?.map((shortcut, index) =>
                            <div key={index} className={style.cardStyle}>
                                <ShortCut {...shortcut} />
                            </div>)}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Home;