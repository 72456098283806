import React, { useState, FC, useEffect, createContext } from 'react';

const POST_EDITOR_ROLE = "post_editor";
const ADMIN_ROLE = "admin";

export interface IUserData {
  user: {
    firstname: string;
    lastname: string;
    email: string;
    lang: ['no', 'en']
  },
  roles: string[];
  menu_links: {
    name: string;
    path: string;
  };
  company: {
    primary_color: string;
    cta_color: string;
    logo_path: string;
  }
}


interface IUserContext {
    isLoading: boolean;
    userData: IUserData;
    setUserData: (arg0: IUserData) => void;
}


const UserContext = React.createContext<any>({});

export const UserProvider = (props:any) => {
  const { children } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUser] = useState<IUserData>();
  
  const [snackbarText, setSnackbarText] = useState('');


  const setUserData = (usrData?:IUserData) => {
    if(usrData){
      setUser(usrData);
    }
    setIsLoading(false);
  };
  
  return (
    <UserContext.Provider
      value={{
        isLoading,
        setUserData,
        userData,
        roles: {
          isAdmin: userData?.roles.includes(ADMIN_ROLE),
          isPostEditor: userData?.roles.includes(POST_EDITOR_ROLE),
        },

        snackbarText: snackbarText,
        setSnackbarText,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;