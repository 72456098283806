import { Link } from 'react-router-dom';
import style from './Header.module.css';
import { Container, Grid, Button } from '@mui/material';
import { useContext } from 'react';
import UserContext from '../context/userContext';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { BASE_ROUTE } from '../api';
const active = "";

/*
<Link href={{ pathname: '/order', }}>
    <Button color={pathname.endsWith("order") ? "primary" : "inherit"}>New Site</Button>
</Link>
*/

function MenuItem(props) {

    const { name, link, active, useDjangoRouter=false } = props;
    
    if(useDjangoRouter) {
        return (<Grid item className={name == active ? style.activeMenuItem : ''}>
            <Button color="inherit" className={active ? 'themeButton' : ''} onClick={() => window.open(link, "_self")}>{name}</Button>
        </Grid>) 
    }

    return (<Grid item className={name == active ? style.activeMenuItem : ''}>
        <Link to={link} className={style.linkItem}>
        <Button color="inherit" className={active ? 'themeButton' : ''}>{name}</Button>
    </Link></Grid>)
}

function Header() {
    const userContext = useContext(UserContext);
    const { userData, roles } = userContext;
    const path = useLocation()
    let { pathname } = path;
    if (pathname.endsWith("/")) pathname = pathname.slice(0, -1);
    return (
        <div className={style.wrapper}>
            <Container maxWidth="xl">
                <Grid container alignItems={"center"} sx={{ height: "50px" }}>
                    <Grid item md={2}>
                        <img src={_.get(userData, "company.logo_path")} alt="Logo" height="35px" />
                    </Grid>
                    <Grid item md={10}>
                        <Grid container justifyContent="center" spacing={5}  className={style.menuItems}>
                            <MenuItem key={-1} name={"Intranet"} link={`${BASE_ROUTE}/`} active={ pathname.endsWith(BASE_ROUTE)} />
                            <MenuItem key={-2} name={"Nyheter"} link={`${BASE_ROUTE}/posts/`} active={pathname.includes(`${BASE_ROUTE}/posts`)}/>
                            {userData?.menu_links.map((item, index) => <MenuItem key={index} {...item} useDjangoRouter={true}/>)}
                            {roles.isAdmin && <MenuItem key={-3} name={"Innstillinger"} link={`${BASE_ROUTE}/settings/general`}  active={pathname.includes(`${BASE_ROUTE}/settings`)}/>}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default Header;