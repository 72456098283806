import LinediagramWithTable from "./shared/linediagram_with_table/LinediagramWithTable"
import PostList from "./shared/news/PostList";
import { IntranetPostPreview } from "./shared/news/PostPreview";
import PostSticky from "./shared/news/PostSticky";
import FlexDiagram from "./shared/flexDiagram/FlexDiagram";

export default {}
export const componentList = [
    {
        key: "sticky_posts",
        comp: PostSticky
    },
    {
        key: "latest_posts",
        comp: IntranetPostPreview,
    },
    {
        key: "linediagram_with_table",
        comp: LinediagramWithTable
    },
    {
        key: "flex_diagrams",
        comp: FlexDiagram
    },
]


