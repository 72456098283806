import { Grid, Typography, TextField, Button } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";

import { HexColorPicker } from "react-colorful";
import Api from "../../api";
import _ from "lodash";
import UploadImage from "../../components/UploadImage";
import { useLocation } from 'react-router-dom';
import Label, { getLabel } from "../../utils";
import UserContext from "../../context/userContext";

const url = '/intranet/settings/general';

export async function generalSettingsLoader() {
    return Api.get(url);
}

interface IPortalSettings {
    primary_color: string;
    logo_path: string;
    intranet_settings: any;
}

export default function General() {
    const userContext = useContext(UserContext);
    const { setSnackbarText, userData } = userContext;
    const data: any = useLoaderData();
    const portalSettings: IPortalSettings = _.get(data, 'settings.value', {});
    const [color, setColor] = useState(portalSettings.primary_color || "#000000");
    const [file, setFile] = useState<any>(portalSettings.logo_path);
    const [showSaveButton, setShowSaveButton] = useState(false);
    
    async function save() {
        const formData = new FormData();
        if (file instanceof File){
            formData.append("file", file);
        }
        formData.append("color", color);

        try {
            const res = await Api.upload(url, formData);
            setSnackbarText(getLabel("snackbar.postSaved", userData.user.lang));

            setShowSaveButton(false);    
        }catch (error) {
            setSnackbarText(getLabel("snackbar.somethingWentWrong", userData.user.lang));

        }
    }

    useEffect(() => {
        const hasChanges = color !== portalSettings.primary_color || file !== portalSettings.logo_path;
        setShowSaveButton(hasChanges);
    }, [file, color]);

    return (
        <>
            <Typography variant="h5"><Label k="settings.general.header" /></Typography>
            <Typography variant="caption" color="text.secondary"><Label k="settings.general.expl" /></Typography>

            <Grid container spacing={2} sx={{ margin: "0rem 0 2rem 0" }} justifyContent={"space-between"}>
                <Grid item md={5}>
                    <Typography variant="h6"><Label k="settings.general.logoHeader" /></Typography>
                    <UploadImage file={file} setFile={setFile} />
                </Grid>
                <Grid item md={5}>
                    <Typography variant="h6" ><Label k="settings.general.systemColorHeader" /></Typography>
                    <HexColorPicker color={color} onChange={setColor} style={{ height: "10rem", width: "100%" }} />
                    <TextField id="standard-basic" variant="standard" size="small" label="Hex" value={color} onChange={(e) => setColor(e.target.value)} sx={{ margin: "1rem 0 0.5rem" }} />
                </Grid>
                <Grid item md={12} sx={{ textAlign: "right" }}>
                    {showSaveButton && <Button variant="outlined" className="themeButton" sx={{ marginTop: "1rem" }} onClick={save}><Label k="settings.general.saveCta" /></Button>}
                </Grid>
            </Grid>
        </>
    )
}