import { Calendar } from 'fullcalendar'
import dayGridPlugin from '@fullcalendar/daygrid'
import { useContext, useEffect } from 'react'
import { Typography } from '@mui/material'
import holidays from './holidays_24_25_26_27.json'
import UserContext from '../../../../context/userContext'
import _ from 'lodash';



export function Calender() {
    const userContext = useContext(UserContext);
    const { userData } = userContext;
    
    useEffect(() => {
        const calendarEl = document.getElementById('mini-calendar')
        const calendar = new Calendar(calendarEl, {
            initialView: 'dayGridMonth',
            headerToolbar: { start: "title", end: "prev,next" },
            locale: _.get(userData, 'user.lang', 'no'),
            height: "auto",
            firstDay: 1,
            eventSources: [
                {
                  events: holidays.map((holiday) => {
                    return {
                        start: holiday.date,
                        allDay: true,
                    }
                }),
                  color: '#f59586',
                },
            ],
            
            
        })
        calendar.render()
    }, [userData])

    return (
        <div style={{padding:"0rem 1rem 1rem 1rem"}}>
            <div id="mini-cal-wrap" >
                {/*<Typography variant="h4" sx={{ display: "inline-block" }}>19</Typography> Mars 2024*/}
                <div id="mini-calendar"></div>

            </div>
        </div>
    )
}