import { Container, Grid, Button, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import style from "./MainsettingsPage.module.css";
import Label from "../../utils";
import ColorLensIcon from '@mui/icons-material/ColorLens';

const icons = [
    {
        label: <Label k="settings.layout.generalCta" />,
        icon: <ColorLensIcon />,
        link: "general"
    },
    {
        label: <Label k="settings.layout.shortcutsCta" />,
        icon: <OpenInNewIcon />,
        link: "shortcuts"
    },
    {
        label: <Label k="settings.layout.postsCta" />,
        icon: <NewspaperIcon />,
        link: "posts"
    }
]


export default function SettingsPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    return (
        <Container>
            <Grid container maxWidth="xl" spacing={2} sx={{ margin: "3rem 0 5rem 0" }} justifyContent={"center"}>
                <Grid item md={3}>
                    <Typography variant="body1" color="grey" sx={{ fontWeight: "400" }}>Intranet</Typography>
                    {icons.map((icon, i) => <div key={i + icon.link}>
                        <Button
                            
                            component="label"
                            fullWidth
                            variant="text"
                            className={style.linkButton + " " + (pathname.endsWith(icon.link) ? style.activeLinkButton : null)}
                            startIcon={icon.icon}
                            href={icon.link}
                            onClick={() => { navigate(icon.link) }}
                        >{icon.label}</Button></div>)}
                </Grid>
                <Grid item md={8}>
                    <Outlet />
                </Grid>
            </Grid>
        </Container>
    )
}