import data from './labels.json';
import { BASE_URL } from './api';
import { useContext } from 'react';
import UserContext from './context/userContext';



export function getLabel(key: string, langCode: string = 'no'){
    const label = data[key][langCode];
    return label;
}

export default function Label(props){
    const userContext = useContext(UserContext);
    const { userData } = userContext;
    const label = getLabel(props.k, userData?.user.lang);
    return <>{label}</>;
}


export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
}