import { Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import Chart, { ChartTypeRegistry } from 'chart.js/auto'
import UserContext from '../../../../context/userContext';


let DiagramData = [
    {
        "diagram": {
            "type": "bar",
            "data": {
                "labels": ["Jan", "Feb", "mars", "April", "Mai", "Juli"],
                "datasets": [{
                    "label": "Antall Solgte varer siste dat",
                    "data": [65, 59, 80, 65, 59, 80],
                    "borderWidth": 1
                }]
            }
        },
        "presentation": {
            "gridWidth": 8,
            "chartId": "salgstall",
            "styling": {
                "height": "16rem"
            }
        }
    },
    {
        "diagram": {
            "type": "pie",
            "data": {
                "labels": [
                    "Red",
                    "Blue",
                    "Green"
                ],
                "datasets": [{
                    "label": "My First Dataset",
                    "data": [300, 50, 100],
                    "hoverOffset": 4
                }]
            }
        },
        "presentation": {
            "gridWidth": 4,
            "chartId": "salgstall_2",
            "styling": {
                "height": "16rem"
            }
        }
    }
]


export default function FlexDiagram(props) {
    const { data } = props;
    console.log(data)
    const userContext = useContext(UserContext);

    useEffect(() => {
        let charts = []
        for (let chartData of data?.value) {
            const ctx: any = document.getElementById(chartData.presentation.chartId);

            let ss: keyof ChartTypeRegistry = chartData.diagram.type as keyof ChartTypeRegistry;
            const chart = new Chart(ctx, {
                type: ss,
                data: chartData.diagram.data,
            });
            charts.push(chart)
        }
        return () => {
            charts.forEach((chart) => chart.destroy());
        };
    }, [userContext]);

    return (
        <Grid container spacing={2} sx={{ padding: "1rem" }}>
            <Grid item md={12}>
                <div className="intranet_tile_header">Salgstall</div>
                <Grid container spacing={1}>
                    {data?.value.map((diagram) => {
                        return <Grid item md={diagram.presentation.gridWidth}>
                            <div style={diagram.presentation.styling}>
                                <canvas id={diagram.presentation.chartId}></canvas>
                            </div>
                        </Grid>
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}