import { useState, useMemo, useEffect, useContext } from 'react';
import EditorJS from '@editorjs/editorjs';
import { Container, TextField, Grid, Button, FormControlLabel, Switch, Typography, Checkbox, Modal, Box, FormGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Label, { getLabel } from '../../../../utils';
import Header from '@editorjs/header';
import List from "@editorjs/list";
import ImageTool from '@editorjs/image';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import style from './Post.module.css';
import Api, { BASE_ROUTE, } from '../../../../api';
import _ from 'lodash';
import { useLoaderData } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadImage from '../../../../components/UploadImage';
import UserContext from '../../../../context/userContext';

export async function postEditLoader(props) {
    const id = _.get(props, 'params.id', 0);
    return Api.get(`/intranet/posts/${id}/edit`);
}

function Tag(props) {
    const { value, active } = props;
    return (
        <div className={style.tagWrapper}>
            <FormControlLabel  control={<Checkbox size="small" />} label={<Typography variant="body2" >{value}</Typography>} checked={active} onChange={() => props.checkedTag(value)} />
        </div>
    )
}

function DeletePostDialog(props) {
    return (
        <Dialog
            fullScreen={false}
            open={props.show}
            onClose={() => { }}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                <Label k="postEdit.deleteDialog.title" />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Label k="postEdit.deleteDialog.text" />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => { props.performDelete(false) }} sx={{marginRight:"2rem"}}>
                    <Label k="postEdit.deleteDialog.cancelCta" />
                </Button>
                <Button color="warning" onClick={() => { props.performDelete(true) }} autoFocus>
                    <Label k="postEdit.deleteDialog.saveCta" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default function PostEdit(props) {
    const navigate = useNavigate();
    const data: any = useLoaderData();
    const { post } = data;
    const userContext = useContext(UserContext);
    const { roles, setSnackbarText, userData } = userContext;

    const [showModal, setShowModal] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);


    const [title, setTitle] = useState('');
    const [publish, setPublish] = useState(false);
    const [sticky, setSticky] = useState(false);
    const [stretchImage, setStretchImage] = useState(false);

    const [file, setFile] = useState(post.image);

    const [allTags, setAllTags] = useState(data.tags);
    const [tags, setTags] = useState([]);

    const [editor, setEditor] = useState(null);

    useEffect(() => {
        setTitle(post.title ?? '');
        setTags(post.tags);
        setSticky(post.is_sticky);
        setPublish(post.is_published);
        setStretchImage(post.stretch_image);
    }, [post]);

    useMemo(() => {
        let editorjsConfig: any = {
            holder: 'editorjs',
            readOnly: false,
            placeholder: getLabel('post.editorPlaceholder'),
            tools: {
                header: {
                    class: Header,
                    config: {
                        placeholder: 'Enter a header',
                    }
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    }
                },
                image: {
                    class: ImageTool,
                    config: {
                        uploader: {
                            async uploadByFile(file) {

                                const formData = new FormData();
                                formData.append("file", file, `${uuidv4()}.${file.name.split('.').pop()}`);
                                return Api.upload(`/intranet/posts/${post.id}/edit/image`, formData);
                            },
                        }
                    }
                }
            }
        }
        if (post.content) editorjsConfig.data = post.content;

        const _editor = new EditorJS(editorjsConfig);
        setEditor(_editor);
    }, []);


    const handleSaveArticle = async () => {
        if (editor) {
            const content = await editor.save();


            const data = {
                title: title,
                content: content,
                tags: tags,
                is_published: publish,
                is_sticky: sticky,
                stretch_image: stretchImage,
            }
            const formData = new FormData();
            if (file instanceof File) {
                formData.append("file", file, `${uuidv4()}.${file.name.split('.').pop()}`);
            }
            formData.append("data", JSON.stringify(data));
            try {
                const response = await Api.upload(`/intranet/posts/${post.id}/edit`, formData);
                setSnackbarText(getLabel('snackbar.postSaved', userData.user.lang));

                navigate(`${BASE_ROUTE}/posts/${post.id}`);
            } catch (error) {
                console.error('Error posting article:', error);
                setSnackbarText(getLabel('snackbar.somethingWentWrong', userData.user.lang));

            }
        }
    };

    function checkedTag(tag) {
        if (tags.includes(tag)) {
            setTags(tags.filter((t) => t !== tag));
        } else {
            setTags([...tags, tag]);
        }
    }

    async function handleDeleteDialogAction(answer: boolean) {
        if (answer) {
            try {
                await Api.delete(`/intranet/posts/${post.id}/edit`);
                setSnackbarText(getLabel('snackbar.deletedPost', userData.user.lang));

                navigate(`${BASE_ROUTE}/posts`);
            } catch (error) {
                console.error('Error deleting post:', error);
                setSnackbarText(getLabel('snackbar.somethingWentWrong', userData.user.lang));

            }
        } else {
            setShowDeleteDialog(false);
        }
    }

    return (
        <div>
            <DeletePostDialog show={showDeleteDialog} performDelete={handleDeleteDialogAction} />
            <Modal
                open={showModal}
                onClose={() => { }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={style.modal}>
                    <Grid container spacing={4} alignItems={""} direction="column">
                        <Grid item >
                            <div className={style.modalTitle}><Label k="postEdit.modal.imgHeader" /></div>
                            <UploadImage file={file} setFile={setFile} />

                        </Grid>

                        <Grid item >
                            <div >
                                <div className={style.modalTitle}><Label k="postEdit.modal.labelHeader" /></div>
                                {allTags.map((tag, index) => <Tag key={index} value={tag.tag} active={tags.includes(tag.tag)} checkedTag={checkedTag} />)}
                            </div>
                        </Grid>
                        <Grid item  >
                            <div className={style.modalTitle}><Label k="postEdit.modal.publishOptionsHeader" /></div>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={publish} onChange={(_, v) => {
                                    if (v == false) setSticky(false);
                                    setPublish(v)
                                }} />} label={<Label k="postEdit.modal.publishSwitch" />} />
                                <FormControlLabel control={<Switch disabled={!publish} checked={sticky} onChange={(_, v) => setSticky(v)} />} label={<Label k="postEdit.modal.stickySwitch" />} />
                                <FormControlLabel control={<Switch checked={stretchImage} onChange={(_, v) => setStretchImage(v)} />} label={<Label k="postEdit.modal.stretchimg" />} />

                            </FormGroup>
                        </Grid>
                        <Grid item md={12} sx={{ textAlign: "right" }}>
                            <Button variant="text" onClick={() => {setShowModal(false); setPublish(post.is_published)}} sx={{ marginRight: "1rem", color:"black" }}><Label k="postEdit.modal.cancelCta" /></Button>
                            <Button variant="outlined" className='themeButton' onClick={handleSaveArticle}><Label k="postEdit.modal.saveCta" /></Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>


            <Container>
                <Grid container justifyContent={"center"} sx={{ marginTop: "1.5rem" }}>
                    <Grid item md={7} sx={{ marginTop: "0rem" }}>
                        {roles.isPostEditor && <Grid container justifyContent={"space-between"} >
                            <Grid item >
                                <Button variant="outlined" className='themeButton' startIcon={<CloseIcon />} onClick={() => { navigate(`${BASE_ROUTE}/posts`) }}><Label k="postEdit.cancelCta" /></Button>
                            </Grid>
                            <Grid item >
                                <Button variant="outlined" className='themeButton' startIcon={<DeleteIcon />}  onClick={() => setShowDeleteDialog(true)}><Label k="postEdit.deleteCta" /></Button>
                            </Grid>
                            <Grid item >
                                <Button variant="outlined"   className='themeButton'startIcon={<SaveIcon />}  onClick={handleSaveArticle}><Label k="postEdit.saveCta" /></Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" className='themeButton' startIcon={<PublishIcon />} onClick={() => {setPublish(true); setShowModal(true)}}><Label k="postEdit.publishCta" /></Button>
                            </Grid>
                        </Grid>}
                        <TextField inputProps={{style:{fontSize:"1.5rem"}}} fullWidth label={<Label k="postEdit.titleLabel" />} sx={{ marginTop: "3rem" }} variant="standard" value={title} onChange={(e) => setTitle(e.target.value)} />
                        <div id="editorjs" style={{ width: "100%", marginTop: "2rem" }}></div>

                    </Grid>
                </Grid>

            </Container>
        </div >
    )
}

