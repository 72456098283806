import { useContext, useEffect, useState } from 'react';
import style from './style.module.css';
import Chart from 'chart.js/auto'
import { useLoaderData } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UserContext from '../../../../context/userContext';


const defaultChartOptions = {
    plugins: {
        legend: {
            display: false
        }
    },
    maintainAspectRatio: false,
    responsive: true,
    resizeDelay: 5,
    scales: {
        y: {
            grace: 0.5,
            beginAtZero: true,
        }
    },
    
    elements: {
        point: {
            radius: 0
        }
    }
};

interface ITableColumn {
    key: string,
    type: ["text", "link"],
    label: string,
}

export default function LinediagramWithTable(props) {
    const { data, header } = props;
    const userContext = useContext(UserContext);
    const [lang, setLang] = useState('no');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);


    useEffect(() => {
        setLang(_.get(userContext, 'userData.user.lang', 'no'))

        const labels = _.get(data, "value.chart.labels", []);
        const series = _.get(data, "value.chart.series", []);
        const fill = _.get(userContext, "userData.company.primary_color")

        const ctx: any = document.getElementById("linechart_with_table");

        const defaultSeriesOptions = {
            label: "Dataserie",
            lineTension: 0.2,
            borderColor: fill,
            borderWidth: 2,
            backgroundColor: fill,
            fill: true,
        };

        const datasets = series?.map((serie: any) => {
            return {
                ...defaultSeriesOptions,
                ...serie
            }
        });
        const chart = new Chart(ctx, {
            type: "line",
            data: {
                labels: labels,
                datasets: datasets
            },
            options: defaultChartOptions
        });

        setRows(_.get(data, "value.table.rows", []))
        setColumns(_.get(data, "value.table.columns", []))


        return () => {
            chart.destroy();
        };
    }, [userContext]);

    const table_data = _.get(data, "value.table", []);





    return (
        <Grid container spacing={2} sx={{ padding: "1rem" }}>
            <Grid item md={12}>
                <div className="intranet_tile_header">{header && header[lang]}</div>
            </Grid>
            <Grid item md={12}>
                <div className={style.wrapper}>
                    <div className={style.chartContainer}>
                        <canvas id="linechart_with_table"></canvas>
                    </div>
                </div>
                <table className={style.table}>
                    <thead>
                        <tr>
                            {columns.map((column: ITableColumn, index) => {
                                return <th key={index + "_"}>{column.label["no"]}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => {
                            let _row = [];
                            for (let column of columns) {
                                let key = column.key;
                                if (!row.hasOwnProperty(key)) continue;

                                if (column.type === "text") {
                                    _row.push(<td key={key}>{row[key]}</td>)
                                } else if (column.type == "link") {
                                    _row.push(
                                        <td key={key} style={column?.styling}>
                                            <Button
                                                variant="text"
                                                size='small'
                                                className='themeButton'
                                                endIcon={<OpenInNewIcon
                                                    fontSize="small" />}
                                                href={row[key]}
                                            >{column?.buttonLabel[lang]}</Button>
                                        </td>
                                    )
                                }
                            }
                            return <tr key={index}>{_row}</tr>;
                        })}

                    </tbody>
                </table>
            </Grid>
        </Grid>
    )
}