import { Grid, Typography } from "@mui/material";
import style from "./Shortcut.module.css"
import { IShortcut, IShortcutLink } from "../../Home";

function ShortCutItem(props: IShortcutLink) {

    return (
        <div className={style.itemWrapper} onClick={() => window.open(props.url, '_blank')}>
            <div>
                {props.image && <img src={props.image} alt="" className={style.shortcutImg} />}
                <Typography  color="text.secondary" variant="body2" sx={{marginTop:"0rem"}}>{props.title}</Typography>
            </div>
        </div>
    )
}

export default function ShortCut(props) {
    const shortcut: IShortcut = props as IShortcut;
    return (
        <div style={{padding: "0.3rem 0.5rem 0 0.5rem"}}>
            <div className="intranet_tile_header" >{shortcut.name}</div>
            <Grid container spacing={0} >
                {shortcut?.link.map((s, index) => <ShortCutItem key={index} {...s} />)}
            </Grid>
        </div>
    )
}