import { useState, useMemo, useEffect, useContext } from 'react';
import EditorJS from '@editorjs/editorjs';
import { Container, TextField, Grid, Button, FormControlLabel, Switch, Typography, Alert } from '@mui/material'
import Label, { formatDate, getLabel } from '../../../../utils';
import Header from '@editorjs/header';
import List from "@editorjs/list";
import ImageTool from '@editorjs/image';
import style from './Post.module.css';
import _ from 'lodash';
import { useLoaderData } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Api, { BASE_ROUTE } from '../../../../api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import UserContext from '../../../../context/userContext';

export async function postLoader(props) {
    const id = _.get(props, 'params.id', 0);
    return Api.get(`/intranet/posts/${id}`);
}

export default function PostView2(props) {
    const navigate = useNavigate();
    const userContext = useContext(UserContext);
    const { roles } = userContext;
    const data: any = useLoaderData();
    const { post } = data;
    
    useMemo(() => {
        let editorjsConfig: any = {
            holder: 'editorjs',
            readOnly: true,
            minHeight: 0,
            tools: {
                header: {
                    class: Header,
                    config: {
                        placeholder: 'Enter a header',
                    }
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                    config: {
                        defaultStyle: 'unordered'
                    }
                },
                image: {
                    class: ImageTool
                }
            }
        }
        if (post.content) editorjsConfig.data = post.content;
        new EditorJS(editorjsConfig);
    }, []);
    return (
        <>
            <Container >
                <Grid container maxWidth={"xl"} justifyContent={"center"} sx={{ margin: "1rem 0 2rem 0", }}>
                    <Grid item md={7} >
                        <Grid container justifyContent={"space-between"} >
                            <Grid item >
                                <Button variant="text" className='themeButton' startIcon={<ArrowBackIcon />} sx={{ color: "black" }} onClick={() => { navigate(`${BASE_ROUTE}/posts`) }}><Label k="postPreview.backCta"/></Button>
                            </Grid>
                            <Grid item>
                                {roles.isPostEditor && <Button variant="text" className='themeButton' startIcon={<EditIcon />} onClick={() => { navigate(`${BASE_ROUTE}/posts/${post.id}/edit`)  }}><Label k="postPreview.editCta"/></Button>}
                            </Grid>
                        </Grid>

                        {!post?.is_published && <Alert severity="info" sx={{marginTop:"1rem"}}>Denne artikkelen er ikke publisert. </Alert>}

                        <Typography variant='h4' sx={{ marginTop: "2rem" }} >{post.title}</Typography>

                        <Grid container justifyContent={"space-between"} alignItems={"flex-end"} sx={{ marginTop: "1rem" }}>
                            <Grid item>
                                <Typography variant="body2" sx={{ marginTop: "1rem" }}>{post.authors.join(", ")}</Typography>
                                <Typography variant="caption" sx={{ marginTop: "0.4rem", display:"block" }}><Label k="postPreview.postCreated"/>: {formatDate(post.created)}</Typography>
                            </Grid>
                            <Grid item md={7} >
                                {post.tags.map((tag) => <div key={tag} className={style.tag}>{tag}</div>)}
                            </Grid>
                        </Grid>
                        <hr />
                        <div className={style.postContainer}>
                            <div id="editorjs" style={{ width: "100%", }}></div>
                        </div>

                    </Grid>
                </Grid>
            </Container>
        </>
    );
}