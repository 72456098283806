import style from "./UploadImage.module.css";
import { Button, styled } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from "react";
import Label from "../utils";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function UploadImage(props:any) {
    const {file, setFile} = props;
    
    function parseSelectedFile(event) {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
        }
    }

    return (
        <>
            <div className={style.previewImageWrapper}>
                {file && <img src={file instanceof File ? URL.createObjectURL(file) : file} alt="Bilde" className={style.previewImage} /> }
            </div>
            <div style={{ textAlign: "center", marginTop:"1rem" }}>
                <Button
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    className="themeButton"
                    onChange={parseSelectedFile}
                    size='small'
                    variant="outlined"

                ><Label k="uploadImage.label" /><VisuallyHiddenInput type="file" accept="image/*"/>
                </Button>
            </div>
        </>
    )
}