import React, { useContext, useRef, useState } from 'react';
// Import Swiper React components
import {Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Grid, Typography, CardMedia } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';

// import required modules
import { Navigation } from 'swiper/modules';
import { IPost } from './interface';
import { get_article_preview } from './PostPreview';
import { BASE_ROUTE } from '../../../../api';
import style from './Post.module.css';
import { useNavigate } from "react-router-dom";
import Label from '../../../../utils';
import UserContext from '../../../../context/userContext';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import _ from 'lodash';

export default function PostSticky(props) {
    const userContext = useContext(UserContext);
    const { userData, } = userContext;

    const posts: IPost[] = props?.data as IPost[];
    const navigate = useNavigate();
    if (!posts) return <></>;

    return (
        <Swiper modules={[Navigation, Autoplay]}
            spaceBetween={20}
            navigation={true}
            autoplay={{ 
                delay: 4000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true,
            }}
            loop={true}
            pagination={{
                dynamicBullets: true,
            }}
            className={style.stickySwiper}
        >

            {posts?.map((post, index) => {
                let image = post.image;
                if (!image) {
                    let firstImage = _.find(post.content.blocks, { type: 'image' });
                    if (firstImage) {
                        image = firstImage.data.file.url;
                    }
                }
                if (!image) {
                    image = _.get(userData, "company.logo_path");
                }


                return (<SwiperSlide key={index} >
                    <Grid key={post.id} container spacing={0}>
                        <Grid item md={5}>
                            <div className={style.stickyImgContainer} style={{ background: `url('${image}')`, backgroundSize: post.stretch_image ? 'cover' : 'contain', backgroundPosition: "center", backgroundRepeat: "no-repeat", width: "100%", maxHeight: "15rem" }}></div>
                        </Grid>
                        <Grid item md={7} sx={{ textAlign: "left" }} style={{ position: "relative", height: "15rem" }}>
                            <div style={{ padding: "1rem 1rem 0 1rem", }}>
                                <Typography variant="h5" component="div">{post.title}</Typography>
                                <Typography color="text.secondary" component="div">{get_article_preview(post.content)}</Typography>
                            </div>
                            <div className={style.stickyBottom}>
                                <div className={style.postPreviewTransitionLayer}></div>
                                <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ height: "4rem", backgroundColor: "white", padding: "0 1rem 0 0.5rem" }}>
                                    <Grid item>
                                        <Button variant="text" endIcon={<KeyboardDoubleArrowRightIcon />} className='themeButton' onClick={() => navigate(`${BASE_ROUTE}/posts/${post.id}`)}><Label k="home.stickyPostCta" /></Button>
                                    </Grid>
                                    <Grid item>
                                        {post.tags.map((tag) => <div key={tag} className={style.tag}>{tag}</div>)}
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </SwiperSlide>)
            })}
        </Swiper>
    );
}
